//import logo from './logo.svg';
//import './App.css';
import Todo from './Todo';
import './Todo.css'

function App() {
  return (
    <div className="App">
      
         < Todo/>
        
    </div>
  );
}

export default App;
